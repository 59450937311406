<template>
  <div>
    <Header :showAction="true"></Header>
    <div class="new-visit container bg-light p-4 mt-4 rounded-3">
      <div class="row" v-if="message">
        <div class="col-md-12">
          <div v-bind:class="`alert alert-${message.type}`">
            <strong v-text="message.prefix"></strong>
            <span v-html="message.message"></span>
          </div>
        </div>
      </div>

      <div class="row" v-if="loading">
        <div class="col-md-12">
          <span>Caricamento...</span>
        </div>
      </div>

      <div class="row" v-if="!message?.block && !loading">
        <div class="col-md-12 text-center">
          <h1
            v-text="
              ticket.id
                ? 'Dati ospite / Guest data'
                : 'New visit @ Casa Fantini'
            "
          ></h1>
          <small
            class="d-block mb-3"
            v-if="ticket.id"
            v-text="ticket.subject"
          ></small>
        </div>
      </div>
      <div class="row" v-if="!message?.block && !loading">
        <div class="col-md-12">
          <form action="" class="form">
            <div class="row" v-if="!ticket.id">
              <div class="col-md-4">
                <label for="visit-date" class="form-label"
                  >Data della visita / Visit date *</label
                >
                <input
                  type="date"
                  class="form-control"
                  id="visit-date"
                  v-model="ticket.data_visita"
                />
              </div>
            </div>

            <div class="row mt-3" v-if="!ticket.id">
              <div class="col-md-12">
                <label for="visit-description" class="form-label mb-0"
                  >Descrizione della visita / Visit description *</label
                >
                <p class="text-muted mt-0">
                  <small>For example: Visit / Architect / Client / Agent</small>
                </p>
                <textarea
                  class="form-control"
                  id="visit-description"
                  rows="3"
                  v-model="ticket.subject"
                ></textarea>
              </div>
            </div>

            <div class="row mt-3"  v-if="!ticket.id">
              <div class="col-md-12">
                <label for="visit-agente" class="form-label"
                  >Agente / Agent *</label
                >
                <select
                  class="form-select"
                  aria-label="Seleziona un elemento dalla lista"
                  id="visit-agente"
                  v-model="ticket.agente"
                >
                  <option value="" disabled selected>Select item from list</option>
                  <option
                    v-for="item in properties.tickets.agente"
                    :key="item.value"
                    v-bind:value="item.value"
                    v-text="item.label"
                  ></option>
                </select>
              </div>
            </div>

            <div id="ticket-section">
              <!-- <div v-if="!ticket.id" class="row mt-5">
                <div class="col-md-12">
                  <h3>
                    Informazioni visita @ Casa Fantini / Visit detail @ Casa
                    Fantini
                  </h3>
                  <hr />
                </div>
              </div> -->

              <div class="row mt-3" v-if="!ticket.id">
                <div class="col-md-12">
                  <span class="d-block mb-2"
                    >Servizi Richiesti / Required services *</span
                  >
                  <div
                    class="form-check"
                    v-for="item in properties.tickets.servizi_richiesti"
                    :key="item.name"
                  >
                    <div class="d-flex flex-column">
                      <div>
                        <input
                          class="form-check-input"
                          type="checkbox"
                          v-bind:value="item.value"
                          v-bind:id="`ticket-services-${item.value}`"
                          v-model="ticket.servizi_richiesti"
                        />
                        <label
                          class="form-check-label"
                          v-bind:for="`ticket-services-${item.value}`"
                          v-text="item.label"
                        ></label>
                      </div>

                      <div class="row mb-3 mt-1" v-if="item.value==='Guida Turistica' && ticket.servizi_richiesti.includes('Guida Turistica')">
                        <div class="col-md-4">
                          <label for="date_and_duration_of_visit" class="form-label"
                            >Date and time of visit and duration *</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            id="date_and_duration_of_visit"
                            v-model="ticket.date_and_duration_of_visit"
                          />
                        </div>
                      </div>
                      <div class="row mb-3 mt-1" v-else-if="item.value==='Cena' && ticket.servizi_richiesti.includes('Cena')">
                        <div class="col-md-4">
                          <label for="date_of_diner" class="form-label"
                            >Date of Dinner *</label
                          >
                          <input
                            type="date"
                            class="form-control"
                            id="date_of_diner"
                            v-model="ticket.date_of_diner"
                          />
                        </div>
                      </div>
                      <div class="row mb-3 mt-1" v-else-if="item.value==='Pranzo' && ticket.servizi_richiesti.includes('Pranzo')">
                        <div class="col-md-4">
                          <label for="date_of_lunch" class="form-label"
                            >Date of Lunch *</label
                          >
                          <input
                            type="date"
                            class="form-control"
                            id="date_of_lunch"
                            v-model="ticket.date_of_lunch"
                          />
                        </div>
                      </div>
                      

                    </div>

                  </div>
                </div>
              </div>

              <div
                class="row mt-3"
                v-if="
                  !ticket.id &&
                  Object.values(ticket.servizi_richiesti).includes('Trasporto')
                "
              >
                <div class="col-md-12">
                  <label for="visit-arrival___departure" class="form-label mb-0"
                    >Arrivo e Partenza / Arrival and Departure</label
                  >
                  <p class="text-muted mt-0">
                    <small
                      >Indicare luogo di arrivo/partenza e orario / Indicate
                      place of arrival/departure and time</small
                    >
                  </p>
                  <textarea
                    class="form-control"
                    id="visit-arrival___departure"
                    rows="3"
                    v-model="ticket.arrival___departure"
                  ></textarea>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-md-6" v-if="!ticket.id">
                  <label for="visit-people-number" class="form-label mb-0"
                    >Numero di persone / Number of people *</label
                  >
                  <p class="text-muted mt-0">
                    <small>Including the accompanying person</small>
                  </p>
                  <input
                    type="number"
                    class="form-control"
                    id="visit-people-number"
                    v-model="ticket.num_persone"
                  />
                </div>
                <div class="col-md-6" v-if="!ticket.id">
                  <label for="visit-night-number" class="form-label mb-0"
                    >Notti Pernottamento / Nights Overnight stay *</label
                  >
                  <p class="text-muted mt-0 invisible"><small>*</small></p>
                  <input
                    type="number"
                    class="form-control"
                    id="visit-night-number"
                    v-model="ticket.notti_pernottamento"
                  />
                </div>
              </div>
            </div>

            <div v-if="!ticket.id" class="row mt-4">
              <div class="col-md-12">
                <h3>Dati accompagnatore / Guest data</h3>
                <hr />
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-md-6">
                <label for="visit-firstname" class="form-label"
                  >Nome / First name *</label
                >
                <input
                  type="text"
                  class="form-control"
                  id="visit-firstname"
                  v-model="contact.firstname"
                />
              </div>
              <div class="col-md-6">
                <label for="visit-lastname" class="form-label"
                  >Cognome / Last name *</label
                >
                <input
                  type="text"
                  class="form-control"
                  id="visit-lastname"
                  v-model="contact.lastname"
                />
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-md-6">
                <label for="visit-city" class="form-label">Città / City</label>
                <input
                  type="text"
                  class="form-control"
                  id="visit-city"
                  v-model="contact.city"
                />
              </div>
              <div class="col-md-6">
                <label for="visit-postal-code" class="form-label"
                  >Codice Postale / Postal code</label
                >
                <input
                  type="text"
                  class="form-control"
                  id="visit-postal-code"
                />
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-md-6"
              :class="contact.nazioni_new == 'Italy' ? 'col-md-6' : 'col-md-12'">
                <label for="visit-country" class="form-label"
                  >Nazione / Country *</label
                >
                <select
                  class="form-select"
                  aria-label="Seleziona un elemento dalla lista"
                  id="visit-country"
                  v-model="contact.nazioni_new"
                >
                  <option value="" disabled selected>Select item from list</option>
                  <option
                    v-for="item in properties.contacts.nazioni_new"
                    :key="item.value"
                    v-bind:value="item.value"
                    v-text="item.label"
                  ></option>
                </select>
              </div>


              <div class="col-md-6"
              v-if="contact.nazioni_new=='Italy'">
                <label for="visit-province" class="form-label"
                  >Provincia / Province *</label
                >
                <select
                  class="form-select"
                  aria-label="Seleziona un elemento dalla lista"
                  id="visit-province"
                  v-model="contact.provincia"
                >
                  <option value="" disabled selected>Select item from list</option>
                  <option
                    v-for="item in properties.contacts.provincia"
                    :key="item.value"
                    v-bind:value="item.value"
                    v-text="item.label"
                  ></option>
                </select>
              </div>



            </div>

            <div class="row mt-3">

              <div class="col-md-6">
                <label for="visit-lingua" class="form-label"
                  >Lingua / Language *</label
                >
                <select
                  class="form-select"
                  aria-label="Seleziona un elemento dalla lista"
                  id="visit-lingua"
                  v-model="contact.lingua"
                >
                  <option value="" disabled selected>
                    Select item from list
                  </option>
                  <option
                    v-for="item in properties.contacts.lingua"
                    :key="item.value"
                    v-bind:value="item.value"
                    v-text="item.label"
                  ></option>
                </select>
              </div>

              <div class="col-md-6">
                <label for="visit-phone" class="form-label"
                  >Numero di telefono / Phone number</label
                >
                <small
                    >  </small
                  >
                <input
                  type="tel"
                  class="form-control"
                  id="visit-phone"
                  v-model="contact.phone"
                />
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-md-12">
                <label for="visit-email" class="form-label">Email *</label>
                <p class="text-muted mt-0">
                  <small
                    >L'indirizzo email di ogni contatto deve essere univoco / 
                    The email address of each contact must be unique</small
                  >
                </p>
                <input
                  type="email"
                  class="form-control"
                  id="visit-email"
                  v-model="contact.email"
                  required
                />
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-md-6">
                <label for="visit-professional-category" class="form-label"
                  >Categoria Professionale / Professional Category *</label
                >
                <select
                  class="form-select"
                  aria-label="Seleziona un elemento dalla lista"
                  id="visit-professional-category"
                  v-model="contact.tipologia_lead"
                >
                  <option value="" disabled selected>
                    Select item from list
                  </option>
                  <option
                    v-for="item in properties.contacts.tipologia_lead"
                    :key="item.value"
                    v-bind:value="item.value"
                    v-text="item.label"
                  ></option>
                </select>
              </div>

              <div class="col-md-6">
                <label for="visit-job_area__multiple_" class="form-label"
                  >Ruolo / Job Area</label
                >
                <select
                  class="form-select"
                  aria-label="Seleziona un elemento dalla lista"
                  id="visit-job_area__multiple_"
                  v-model="contact.job_area__multiple_"
                >
                 <option value="" disabled selected>
                    Select item from list
                  </option>
                  <option
                    v-for="item in properties.contacts.job_area__multiple_"
                    :key="item.value"
                    v-bind:value="item.value"
                    v-text="item.label"
                  ></option>
                </select>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-md-12">
                <label for="visit-room" class="form-label"
                  >Camera / Room *</label
                >
                <select
                  class="form-select"
                  aria-label="Seleziona un elemento dalla lista"
                  id="visit-room"
                  v-model="contact.camera"
                >
                  <option value="" disabled selected>
                    Select item from list
                  </option>
                  <option
                    v-for="item in properties.contacts.camera"
                    :key="item.value"
                    v-bind:value="item.value"
                    v-text="item.label"
                  ></option>
                </select>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-md-12">
                <label for="visit-notes" class="form-label mb-0"
                  >Note / Notes <span v-if="contact.camera && contact.camera!=='Singola'">*</span></label
                >
                <p class="text-muted mt-0">
                  <small
                    >In caso di camera doppia indicare il nome dell'ospite che
                    condividerà la camera / Indicate the name of the guest
                    sharing the room in the case of a double room</small
                  >
                </p>
                <textarea
                  class="form-control"
                  id="visit-notes"
                  rows="3"
                  v-model="contact.note"
                ></textarea>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-md-12">
                <button
                  type="button"
                  class="btn btn-primary w-100"
                  @click="submit"
                  v-text="
                    ticket.id
                      ? 'Registrazione Ospite / Register Guest'
                      : 'Registrazione Visita / Register Visit'
                  "
                ></button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import { HubspotController } from "../controller/hubspot";
import Footer from "../components/Footer.vue";
import Header from "../components/Header.vue";

export default {
  data() {
    return {
      loading: true,
      message: null,
      hsController: new HubspotController(),
      ticket: {
        servizi_richiesti: [],
        agente: ""
      },
      contact: {
        tipologia_lead: "",
        lingua: "",
        job_area__multiple_: "",
        nazioni_new: "",
        camera: "",
        provincia: ""
      },
      properties: {
        contacts: {
          camera: [],
          tipologia_lead: [],
          nazioni_new: [],
          lingua: [],
          job_area__multiple_: [],
          provincia: []
        },
        tickets: {
          servizi_richiesti: [],
          agente: []
        },
      },
    };
  },

  async mounted() {
    this.init();
  },

  watch: {
    $route: function (to, from) {
      this.init();
    },
  },

  methods: {
    async init() {
      document.title = "Fantini | Caricamento...";
      this.loading = true;
      const ticketId = this.$route.query.ticketId;

      Object.keys(this.properties).forEach(async (propType) => {
        Object.keys(this.properties[propType]).forEach(async (key) => {
          console.log(`Prop key: ${key}`);
          try {
            const prop = await this.hsController.getProperty(propType, key);
            this.properties[propType][key] = prop.options.filter(
              (opt) => key === "tipologia_lead" || !opt.hidden
            );
          } catch (e) {
            this.loading = false;
            this.message = {
              type: "danger",
              prefix: "Attenzione:",
              message:
                "Errore durante il caricamento delle proprietà del form, riprovare più tardi",
            };
            return false;
          }
        });
      });

      if (ticketId) {
        await this.hsController
          .getTicket(ticketId)
          .then((ticket) => {
            if (
              ticket.associations.contacts.results.length >=
              parseInt(ticket.properties.num_persone)
            ) {
              this.$router.push({
                path: "/complete",
              });
            }

            this.ticket = ticket.properties;
            this.ticket.id = ticket.id;
          })
          .catch((e) => {
            console.log(e);
            this.message = {
              type: "danger",
              prefix: "Attenzione:",
              blockFlag: true,
              message:
                "Ticket non trovato, potrebbe essere stato cancellato oppure l'ID non essere corretto",
            };
          });
      }

      document.title = "Fantini | " + (this.ticket.subject ?? "Nuova visita");
      this.loading = false;
    },

    async submit() {
      this.message = null;

      const showWarning = (message) => {
        this.message = {
          type: "warning",
          prefix: "Attenzione: ",
          message: message,
        };
        window.scrollTo(0, 0);
      };



      if (!this.ticket.id) {
        if (!this.ticket.data_visita) {
          showWarning("Il campo Data visita è obbligatorio");
          return false;
        }
        if (!this.ticket.agente) {
          showWarning("The field Agent is required");
          return false;
        }
        if (!this.ticket.subject) {
          showWarning("The field Description of the visit is required");
          return false;
        }
        if (!Object.keys(this.ticket.servizi_richiesti).length) {
          showWarning("The field Required services is required");
          return false;
        }
        if (this.ticket.servizi_richiesti.includes('Guida Turistica') && !this.ticket.date_and_duration_of_visit) {
          showWarning("The field Date and time of visit and duration is required");
          return false;
        }
        if (this.ticket.servizi_richiesti.includes('Cena') && !this.ticket.date_of_diner) {
          showWarning("The field Date of Dinner is required");
          return false;
        }
        if (this.ticket.servizi_richiesti.includes('Pranzo') && !this.ticket.date_of_lunch) {
          showWarning("The field Date of Lunch is required");
          return false;
        }
        if (!this.ticket.num_persone) {
          showWarning("The field Number of persons is required");
          return false;
        }
        if (!this.ticket.notti_pernottamento) {
          showWarning("The field Overnight nights is required");
          return false;
        }
        if (!this.contact.firstname) {
          showWarning("The field Name is required");
          return false;
        }
        if (!this.contact.lastname) {
          showWarning("The field Surname is required");
          return false;
        }
        if (!this.contact.nazioni_new) {
          showWarning("The field Country is required");
          return false;
        }
        if (this.contact.nazioni_new==="Italy" && !this.contact.provincia) {
          showWarning("The field Province is required");
          return false;
        }
        if (!this.contact.lingua) {
          showWarning("The field Language is required");
          return false;
        }
        if (!this.contact.email) {
          showWarning("The field E-mail is required");
          return false;
        }
        if (!this.contact.tipologia_lead) {
          showWarning("IThe field Professional category is required");
          return false;
        }
        if (!this.contact.camera) {
          showWarning("The field Room is required");
          return false;
        }
      } else {
        if (!this.contact.firstname) {
          showWarning("The field Name is required");
          return false;
        }
        if (!this.contact.lastname) {
          showWarning("The field Surname is required");
          return false;
        }
        if (!this.contact.nazioni_new) {
          showWarning("The field Country is required");
          return false;
        }
        if (this.contact.nazioni_new==="Italy" && !this.contact.provincia) {
          showWarning("The field Province is required");
          return false;
        }
        if (!this.contact.lingua) {
          showWarning("The field Language is required");
          return false;
        }
        if (!this.contact.email) {
          showWarning("The field E-mail is required");
          return false;
        }
        if (!this.contact.tipologia_lead) {
          showWarning("IThe field Professional category is required");
          return false;
        }
        if (!this.contact.camera) {
          showWarning("The field Room is required");
          return false;
        }
      }

      if (this.contact.camera && this.contact.camera!=="Singola" && !this.contact.note) {
        showWarning("The field Note is required");
        return false;
      }

      this.loading = true;

      // Se il ticket non è stato caricato, significa che è da creare
      if (!this.ticket.id) {
        try {
          this.ticket = await this.hsController.createTicket(
            this.ticket,
            this.contact
          );
          console.log(this.ticket);
        } catch (e) {
          console.error(e);
          this.loading = false;
          this.message = {
            type: "danger",
            prefix: "Attenzione:",
            message:
              "Errore durante la creazione del ticket, ricontrollare i campi",
          };
          return false;
        }
      }

      // Creo il contatto a partire dalle informazioni immesse nel form
      try {
        const response = await this.hsController.createContact(
          this.contact,
          this.ticket
        );
        this.contact = response.body;
      } catch (e) {
        console.error(e);
        this.loading = false;
        this.message = {
          type: "danger",
          prefix: "Attenzione:",
          message: `Errore durante la creazione del contatto, ricontrollare i campi`,
        };
        return false;
      }

      // Associo il contatto al ticket
      try {
        const response = await this.hsController.associate(
          this.ticket.id,
          this.contact.id
        );
      } catch (e) {
        console.error(e);
        this.loading = false;
        this.message = {
          type: "danger",
          prefix: "Attenzione:",
          message: `Errore durante l'associazione del contatto, ricontrollare i campi`,
        };
        return false;
      }

      this.loading = false;

      this.gotoWithTicket();
    },

    gotoWithTicket() {
      this.$router.push({
        path: "/visits",
        query: {
          ticketId: this.ticket.id,
        },
      });
    },
  },

  components: {
    Header,
    Footer,
  },
};
</script>
